import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Topics from "../../components/topics";
import { graphql } from "gatsby";

const TopicsIndex = ({ data }) => {
  const topics = data.allMicrocmsTopics.edges;
  return (
    <Layout>
      <Seo title="トピックス一覧" />
      <div className="flex flex-col justify-center items-center p-5 md:p-20 bg-gray-50">
        <h1 className="sm:text-3xl text-2xl font-medium text-gray-600 mb-2">
          トピックス一覧
        </h1>
      </div>
      <main className="container mx-auto max-w-4xl px-0 md:px-5 py-5 md:py-10">
        {topics && <Topics topics={topics} />}
      </main>
    </Layout>
  );
};

export default TopicsIndex;

export const query = graphql`
  query AllTopics {
    allMicrocmsTopics(sort: { order: DESC, fields: publishedAt }) {
      edges {
        node {
          topicsId
          publishedAt(formatString: "YYYY-MM-DD")
          title
        }
      }
    }
  }
`;
