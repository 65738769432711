import React from "react";
import { Link } from "gatsby";

const Topics = ({ topics, bg }) => {
  return (
    <div className={bg && "bg-steel-50"}>
      <ul className="p-5 md:p-10 space-y-3 text-sm md:text-base container mx-auto max-w-4xl">
        {topics.map(({ node }) => (
          <li key={node.topicsId}>
            <Link
              to={`/topics/${node.topicsId}`}
              className="hover:text-steel-400"
            >
              <div className="flex">
                <div className="w-1/4 md:w-1/6">{node.publishedAt}</div>
                <div className="w-3/4 md:w-5/6">{node.title}</div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Topics;
